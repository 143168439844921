import { Route, Routes, useNavigate } from "react-router-dom";
import "./MyApp/Css/style.scss";
import "./MyApp/Css/Navbar.scss";
import "./MyApp/Css/StyleV.scss";
import "./MyApp/Css/Style_lc.scss";
import "./MyApp/Css/Theme3_LC.scss";
import "./MyApp/Css/Screen1600Plus.scss";
import "./MyApp/Css/tabletcss.scss";
import "./MyApp/Css/mobilecss.scss";
import "./MyApp/Css/Ap.css";
import "./MyApp/Css/Draggable.css";
// import "./MyApp/Css/Edit.css";
// import "./MyApp/Css/HomePage.css";

import HomePage from "./MyApp/Pages/HomePage";
import Footer from "./MyApp/Component/Footer";
import NavBar from "./MyApp/Component/NavBar";
import ProductSearch from "./MyApp/Pages/ProductSearchPage/ProductSearch";
import ProductDetailPage from "./MyApp/Pages/ProductDetailPage/ProductDetailPage";
import CartPage from "./MyApp/Pages/CartPage/CartPage";
import AdminPage from "./MyApp/Pages/AdminPage/AdminPage";
import BuyWithLens from "./MyApp/Pages/BuyWithLensPage/BuyWithLens";
import MobileBottomMentBtn from "./MyApp/Component/Button/MobileBottomMentBtn";
import { useEffect, useState } from "react";
import FavouritepageMobile from "./MyApp/Pages/MobilePage/FavouritepageMobile";
import ProductDetailPage2 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage2";
import CartPage2 from "./MyApp/Pages/CartPage/CartPage2";
import CartPage3 from "./MyApp/Pages/CartPage/CartPage3";
import ProductDetailPage3 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage3";
import BuyWithLens2 from "./MyApp/Pages/BuyWithLensPage/BuyWithLens2";
import ContactLensDetail from "./MyApp/Pages/ContactLensDetailPage/ContactLensDetail";
import ProductDetailPage5 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage5";
import ProductDetailPage4 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage4";
import ProductDetailPage6 from "./MyApp/Pages/ProductDetailPage/ProductDetailPage6";
import MyContext from "./MyApp/MyContext";
import NotFound from "./MyApp/Pages/NotFound";
import SizeManual from "./MyApp/Pages/GuidePages/SizeManual";
import PrescriptionGuide from "./MyApp/Pages/GuidePages/PrescriptionGuide";
import FaceShapeGuide from "./MyApp/Pages/GuidePages/FaceShapeGuide";
import SquareBoxImage from "./MyApp/Component/Sections/SquareBoxImage";
import ProductSearchWithBanner from "./MyApp/Pages/ProductSearchPage/ProductSearchWithBanner";
import User from "./API/API_SERVISES/User";
import WearTheTrend from "./MyApp/Component/ItemCategoryViewer_LC/WearTheTrend";
import OurPurposeBanner_Lc from "./MyApp/Component/ItemCategoryViewer_LC/OurPurposeBanner_Lc";
import BannerSlider1_Lc from "./MyApp/Component/ItemCategoryViewer_LC/BannerSlider1_Lc";
import Banner1_Lc from "./MyApp/Component/ItemCategoryViewer_LC/Banner1_Lc";
import CarouselLensLc from "./MyApp/Pages/HomepageLc/CarouselLensLc";
import NameWithBanner_Lc from "./MyApp/Component/ItemCategoryViewer_LC/NameWithBanner_Lc";
import ProductList from "./MyApp/Pages/ProductSearchPage/ProductList";
import ProductDetailTheme from "./MyApp/Pages/ProductDetailPage/ProductDetailTheme";
import ProductSliderImages_Lc from "./MyApp/Component/ItemCategoryViewer_LC/ProductSliderImages_Lc";
//import MyAccount from "./MyApp/Pages/AdminPage/MyAccount";
import WishListTheme from "./MyApp/Component/Sections/WishListTheme";
import LoginPanel_Lc from "./MyApp/Component/Modal/LoginPanel_Lc";
import ItemMasterApi from "../src/API/API_SERVISES/ItemMaster";
import MyAccount from "./MyApp/Component/Sections/MyAccount";
import Order from "./API/API_SERVISES/Order";
import TrackOrder from "./MyApp/Component/Sections/TrackOrder";
import ProductDetailTheme1CL from "./MyApp/Pages/ProductDetailPage/ProductDetailTheme1CL";
import Checkout from "./MyApp/Pages/CartPage/Checkout";
import FindThePerfectFit from "./MyApp/Component/ItemCategoryViewer_LC/FindThePerfectFit";
import ContactLenseMore_Lc from "./MyApp/Component/ItemCategoryViewer_LC/ContactLenseMore_Lc";
import ButItYourWay_Lc from "./MyApp/Component/ItemCategoryViewer_LC/ButItYourWay_Lc";
import ShippingAddress from "./MyApp/Pages/CartPage/ShippingAddress";
import CompanyApi from "./API/API_SERVISES/Company";
import PaymentMethods from "./MyApp/Pages/CartPage/PaymentMethods";
import Appointment from "./MyApp/Pages/AppointmentPage/Appointment";
import Bookappoinment from "./MyApp/Pages/AppointmentPage/AppointmentComponents/BookAppointmen/Bookappoinment";
import Phonenumber from "./MyApp/Pages/AppointmentPage/AppointmentComponents/PhoneNumber/Phonenumber";
import Form from "./MyApp/Pages/AppointmentPage/AppointmentComponents/Form/Form";
import CheckOutAndGeneralSetting from "./API/API_SERVISES/CheckoutAndGeneralSetting";
import ItemMaster from "../src/API/API_SERVISES/ItemMaster";
import ProductCart11 from "./MyApp/Component/Card/ProductCart11";
import ShopBanner from "./API/API_SERVISES/Shopbanner";
import ItemGroup from "./API/API_SERVISES/ItemGroup";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function App() {
  // SUGGETION
  const [suggestion, setSuggetion] = useState("")
  const [Itemvariation, setItemvariation] = useState([]);
  console.log(Itemvariation);

  const [DefaultAddressChange, setDefaultAddressChange] = useState(false);
  const [LoginShow, setLoginShow] = useState(true);
  const [myAccount, setMyAccount] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const [checkCredentialsPopup, setCheckCredentialsPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [currentUserData, setCurrentUserData] = useState([]);
  const [EcomToken, setEcomToken] = useState("");
  const openCredentialModal = () => {
    setCheckCredentialsPopup(!checkCredentialsPopup);
  };
  const [productSearch, setProductSearch] = useState([]);
  const [productlist, setProductList] = useState([]);
  const [wishListItems, setWishListItems] = useState([]);
  const [attributeList, setAttributeList] = useState([]);
  const [wishLisHandle, setwishLisHandle] = useState(false);
  const [wishList, setWishList] = useState([]);
  const [InputValues, setInputValues] = useState([]);
  const [itemDataLens, setItemDataLens] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [myAccountID, setMyAccountID] = useState([]);
  const [companyData, setCompanyData] = useState("");
  const [loginData, setLoginData] = useState({});
  const [inputCartList, setInputCartList] = useState([]);
  const [homeEyeCheckUpData, setHomeEyeCheckUpData] = useState([]);
  const [shopBanner, setShopBanner] = useState([]);

  console.log(wishList);

  const fetchShopBannerData = async () => {
    try {
      const data = await ShopBanner.GetShopBanners();
      if (data) {
        setShopBanner(data);
      }
    } catch (error) {
      console.error("Error fetching shop banners:", error);
    }
  };
  useEffect(() => {
    if (currentUser) {
      fetchShopBannerData();
    }
  }, [currentUser]);

  console.log(shopBanner);

  const checkCredentials = async () => {
    // debugger
    const data = await User.checkCredentials();
    console.log(data);
    if (data === false) {
      openCredentialModal();
    } else {
      const currentUserData = await User.GetCurrentUser();

      console.log(currentUserData);
      if (currentUserData) {
        setCurrentUserData(currentUserData);
        setCurrentUser(true);
      }
    }
  };

  useEffect(() => {
    checkCredentials();
  }, []);

  const sendToken = async () => {
    if (EcomToken == "" && EcomToken == undefined) {
      alert("Check the Token and try again");
    } else {
      const data = await User.SendToken(EcomToken);
      if (data == true) {
        alert("Lets go");
        window.location.reload();
      } else {
        alert("Check the Token and try again");
      }
    }
  };

  const handletoken = (e) => {
    var token = e.target.value;
    setEcomToken(token);
  };
  const fetchEyeCheckUpData = async () => {
    if (currentUser) {
      try {
        const response =
          await CheckOutAndGeneralSetting.GetEyeCheckUpAndOptiSett();
        console.log(response.result);
        if (response.isSuccess) {
          setHomeEyeCheckUpData(response.result);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchEyeCheckUpData();
  }, [currentUser]);

  // const myaccountmodal = () => {
  //   setMyAccount(!myAccount);
  //   document.body.style.overflow = myAccount ? "auto" : "hidden";
  //   signupshow();
  // };
  const navigate = useNavigate();
  const userLogin = localStorage.getItem("accountLogin");
  const userArray = userLogin ? JSON.parse(userLogin) : null;

  const myaccountmodal = () => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;

    if (userArray) {
      // UserArray is present, navigate to "/MyAccountPage"
      // setMyAccount(!myAccount);
      navigate("/admin");
    } else {
      // UserArray is not present, continue with your existing logic
      setMyAccount(!myAccount);
      document.body.style.overflow = myAccount ? "auto" : "hidden";
      signupshow();
    }
  };

  const [loginPanel, setLoginPanel] = useState(true);
  const [signin, setSignin] = useState(false);
  const [getOtp, setgetOtp] = useState(false);
  const [forgot, setforgot] = useState(false);
  const [ResetPassword, setResetPassword] = useState(false);
  const [itemGroupData, setItemGroupData] = useState([]);

  const signupshow = () => {
    setLoginPanel(true);
    setSignin(false);
    setgetOtp(false);
    setforgot(false);
    setResetPassword(false);
  };
  const ResetPasswordUI = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false);
    setforgot(false);
    setResetPassword(true);
  };

  const GoToOTP = async () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(true);
    setforgot(false);
    setResetPassword(false);
  };
  const signupshow1 = () => {
    setLoginPanel(false);
    setSignin(true);
    setgetOtp(false);
    setforgot(false);
    setResetPassword(false);
  };

  const forgotPassword = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false);
    setforgot(true);
    setResetPassword(false);
  };

  const [productEnquiry, setProductEnquiry] = useState(false);
  const [searchName, setSearchName] = useState([]);

  const fetchData = async () => {
    if (currentUser) {
      // // // debugger
      try {
        const data = await CompanyApi.GetCompany();
        const data1 = await CompanyApi.GetCompanyById(data[0].id);
        // console.log(data1);
        setCompanyData(data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentUser]);

  const fetchItemData = async () => {
    if (currentUser) {
      try {
        const categoryName = await ItemMasterApi.GetItemMaster();
        console.log(categoryName);
        setProductList(categoryName);
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    }
  };
  useEffect(() => {
    fetchItemData();
  }, [currentUser]);

  // console.log("maxsale price", productlist);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        // setLoading(true);
        // alert("ig");
        try {
          const categoryName = await ItemGroup.GetItemGroup();
          // console.log("categoryName")
          // console.log(categoryName)
          const categoryData = categoryName
            .filter(
              (item) =>
                item.showInCollection == true && item.imageShowOnError == 2
            )
            .slice()
            .sort((a, b) => a.displayOrder - b.displayOrder);
          setItemGroupData(categoryName);
          // setLoading(false);
        } catch (error) {
          console.error("Error Fetching Data:", error);
        }
      }
    };
    fetchData();
  }, [currentUser]);

  console.log("Item Group Data", itemGroupData);

  const calculateTotalPrice = () => {
    let productQuantity = 0;
    console.log(InputValues);
    if (InputValues.length > 0) {
      InputValues?.forEach((product) => {
        productQuantity += product.quantity;
      });
      setTotalQuantity(productQuantity);
    }
  };
  useEffect(() => {
    calculateTotalPrice();
  }, [InputValues]);
  console.log(InputValues);
  // const [cartData, setCartData] = useState();
  // ***************{ Get Cart Details}********************
  const fetchDataCart = async () => {
    // debugger;
    if (currentUser) {
      var loginDataString = localStorage.getItem("accountLogin"); // This is a string
      var loginData = JSON.parse(loginDataString);
      // const LatestCartData = await Order.getCartList(
      //   loginData ? loginData.d2Id || 0 : 0
      // );
      // setCartData(LatestCartData);

      setLoginData(loginData);
      try {
        // const filteredData = [
        //   {
        //     fieldName: "d2AccountId",
        //     operatorName: "equal",
        //     compareValue: loginData ? loginData.d2Id : 0,
        //   },
        // ];
        const val = await Order.getCartList(
          loginData ? loginData.d2Id || 0 : 0
        );
        console.log(val);
        setInputValues(val);
        // setInputCartList(val);
        // calculateTotalPrice()
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    // if (currentUser) {
    fetchDataCart();
    // }
  }, [currentUser]);

  const fetchDataWish = async () => {
    debugger;
    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      setLoginData(loginData);
      const filteredData = [
        {
          fieldName: "d1AccountId",
          operatorName: "equal",
          compareValue: loginData.d1Id,
        },
      ];

      if (currentUser) {
        try {
          const data = await Order.getGetAllFavouriteItemList(filteredData);
          setWishList(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {
      setWishList([]);
    }
  };

  useEffect(() => {
    fetchDataWish();
  }, [currentUser]);

  const myaccountmodal1 = () => {
    setMyAccount(!myAccount);
    document.body.style.overflow = myAccount ? "auto" : "hidden";
  };

  const openproductenquiry = () => {
    setProductEnquiry(!productEnquiry);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const imageBaseUrl =
    // "http://localhost:5000/api/v1/ItemMaster/Image?D1ItemId=";
    `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;
  // "https://tc50.yes-bm.com/api/v1/ItemMaster/Image?D1ItemId=";
  // http://192.168.1.5/YESBM-API/api/v1/ItemMaster/Image?D1ItemId=20
  // http://192.168.1.5/YESBM-API/api/v1/ItemMaster/Image?D1ItemId=13
  console.log("imageurl", imageBaseUrl);

  const logoCompanyUrl = `${WebApiUrl}/api/v1/Company/Image?ImageId=`;
  console.log("imageurl", logoCompanyUrl);

  const RemoveFromCart = async (item) => {
    // debugger
    var loginDataString = localStorage.getItem("accountLogin"); // This i~s a string
    var loginData = JSON.parse(loginDataString); // Now it's an object
    // if (cartData.length !== 0) {
    //   var quantityFromCartData = cartData.filter(item1 => (item1.d1ItemId === item.d1Id))[0];
    // }
    const data = {
      d1ItemId: item.d1Id || item.d1ItemId,
      d2ItemId: item.d2Id || item.d2ItemId,
      d1AccountId: loginData ? loginData.d1Id || 0 : 0,
      d2AccountId: loginData ? loginData.d2Id || 0 : 0,
      userId: 1,
      quantity: 1,
      unit: "Pcs",
      clientId: 0,
      itemPrice: item.salePrice,
      lensPrice: item.lensPrice,
      lensName: item.lensName,
      coatingPrice: item.coatingPrice,
      coatingName: item.coatingName,

      box_R: item.boxesRight,
      box_L: item.boxesLeft,
      sph_R: item.sphRight,
      sph_L: item.sphLeft,
      cyl_R: item.cylRight,
      cyl_L: item.cylLeft,
      axis_R: item.axisRight,
      axis_L: item.axisLeft,
      add_R: item.addRight,
      add_L: item.addLeft,
      dia_R: item.diaRight,
      dia_L: item.diaLeft,
      bC_R: item.bcRight,
      bC_L: item.bcLeft,
    };
    const isSuccess = await Order.RemoveEcomCartDetail(data);
    if (isSuccess) {
      fetchDataCart();
      // fetchDataCartList();
      setInputValues({
        d1ItemId: " ",
        d2ItemId: " ",
        d1AccountId: " ",
        d2AccountId: " ",
        userId: " ",
        quantity: " ",
        unit: " ",
        itemPrice: " ",
        clientId: "",
        itemPrice: "",
        lensPrice: "",
        lensName: "",
        coatingPrice: "",
        coatingName: "",

        box_R: "",
        box_L: "",
        sph_R: "",
        sph_L: "",
        cyl_R: "",
        cyl_L: "",
        axis_R: "",
        axis_L: "",
        add_R: "",
        add_L: "",
        dia_R: "",
        dia_L: "",
        bC_R: "",
        bC_L: "",
      });
      // calculateTotalAmount();
    }
  };
  //useEffect(() => {
    //fetchVariations();
  //}, [currentUser]);

  const fetchVariations = async (d1Id) => {
    if (currentUser) {
      try {
        var data = await ItemMaster.EcomItemsVariation(d1Id);
        if (data) {
          setItemvariation(data);

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <>
      <MyContext.Provider
        value={{
          productEnquiry,
          imageBaseUrl,
          logoCompanyUrl,
          openproductenquiry,
          myaccountmodal,
          totalQuantity,
          setTotalQuantity,
          setProductSearch,
          productSearch,
          searchName,
          setSearchName,
          productlist,
          setProductList,
          wishListItems,
          setWishListItems,
          attributeList,
          wishLisHandle,
          setwishLisHandle,
          setAttributeList,
          myAccount,
          signupshow1,
          setMyAccount,
          loginPanel,
          signin,
          getOtp,
          GoToOTP,
          signupshow,
          myaccountmodal1,
          setLoginPanel,
          LoginShow,
          setLoginShow,
          forgotPassword,
          forgot,
          setforgot,
          ResetPassword,
          ResetPasswordUI,
          DefaultAddressChange,
          setDefaultAddressChange,
          wishList,
          currentUser,
          setWishList,
          fetchDataWish,
          InputValues,
          setInputValues,
          fetchDataCart,
          fetchDataWish,
          itemDataLens,
          setItemDataLens,
          totalPrice,
          setTotalPrice,
          myAccountID,
          setMyAccountID,
          Itemvariation,
          companyData,
          fetchVariations,
          setCompanyData,
          RemoveFromCart,
          inputCartList,
          setInputCartList,
          homeEyeCheckUpData,
          shopBanner,
          itemGroupData,
          // maxSalePrice,
          //fetchVariations,
          // setMaxSalePrice,
          // fetchDataCartList
        }}
      >
        {wishLisHandle && (
          <div className="fixed-bottom">
            <WishListTheme />
          </div>
        )}
        <NavBar wishList={wishList} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/WishListTheme/:id" element={<WishListTheme />} />
          <Route path="/productsearch" element={<ProductSearch />} />
          <Route path="/productdetail/:id" element={<ProductDetailPage />} />
          <Route path="/pc11" element={<ProductCart11 />} />
          <Route path="/productdetail2/:id" element={<ProductDetailPage2 />} />
          <Route path="/productdetail3/:id" element={<ProductDetailPage3 />} />
          <Route path="/productdetail4/:id" element={<ProductDetailPage4 />} />
          <Route path="/productdetail5/:id" element={<ProductDetailPage5 />} />
          <Route path="/productdetail6/:id" element={<ProductDetailPage6 />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/cart2" element={<CartPage2 />} />
          <Route path="/cart3" element={<CartPage3 />} />
          <Route path="/ShippingAddress" element={<ShippingAddress />} />
          <Route path="/PaymentMethods" element={<PaymentMethods />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/buywithlens" element={<BuyWithLens />} />
          <Route path="/buywithlens2" element={<BuyWithLens2 />} />
          <Route path="/fav" element={<FavouritepageMobile />} />
          <Route path="/contactlensdetail" element={<ContactLensDetail />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/SizeManual" element={<SizeManual />} />
          <Route path="/PrescriptionGuide" element={<PrescriptionGuide />} />
          <Route path="/FaceShapeGuide" element={<FaceShapeGuide />} />
          <Route path="/SquareBoxImage" element={<SquareBoxImage />} />
          <Route path="/WearTheTrend" element={<WearTheTrend />} />
          <Route
            path="/OurPurposeBanner_Lc"
            element={<OurPurposeBanner_Lc />}
          />
          <Route path="/TrackOrder" element={<TrackOrder />} />
          <Route path="/FindThePerfectFit" element={<FindThePerfectFit />} />
          <Route
            path="/ContactLenseMore_Lc"
            element={<ContactLenseMore_Lc />}
          />
          <Route path="/ButItYourWay_Lc" element={<ButItYourWay_Lc />} />
          <Route
            path="/ProductDetailTheme1CL/:id"
            element={<ProductDetailTheme1CL />}
          />
          <Route
            path="/OurPurposeBanner_Lc"
            element={<OurPurposeBanner_Lc />}
          />
          <Route path="/BannerSlider1_Lc" element={<BannerSlider1_Lc />} />
          <Route
            path="/ProductSearchWithBanner"
            element={<ProductSearchWithBanner />}
          />
          <Route path="/Banner1_Lc" element={<Banner1_Lc />} />
          <Route path="/CarouselLensLc" element={<CarouselLensLc />} />
          <Route path="/NameWithBanner_Lc" element={<NameWithBanner_Lc />} />
          <Route path="/ProductList" element={<ProductList />} />
          <Route
            path="/ProductDetailTheme/:id"
            element={<ProductDetailTheme />}
          />
          <Route
            path="/ProductSliderImages_Lc"
            element={<ProductSliderImages_Lc />}
          />
          <Route path="/MyAccount" element={<MyAccount />} />

          <Route path="/LoginPanel_Lc" element={<LoginPanel_Lc />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/bookappointment" element={<Bookappoinment />} />
          <Route path="/phonenumber" element={<Phonenumber />} />
          <Route path="/form" element={<Form />} />
        </Routes>
        <MobileBottomMentBtn />
        <Footer currentUser={currentUser} />
        {/* <Footer_lc /> */}
        {myAccount && <MyAccount close={myaccountmodal} />}

        {checkCredentialsPopup && (
          <div className="modal-back">
            <div
              className="account-modal"
              style={{
                height: "120px",
                flexDirection: "column",
                paddingBottom: "10px",
              }}
            >
              <div
                className="input-group"
                style={{ width: "90%", margin: "auto 0" }}
              >
                <label htmlFor="username">Enter Token</label>
                <input
                  type="text"
                  onChange={(e) => handletoken(e)}
                  id="username"
                  placeholder=""
                  name=""
                />
              </div>
              <button
                style={{
                  padding: "5px 20px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "rgba(167, 139, 250, 1)",
                }}
                onClick={sendToken}
              >
                {" "}
                Submit{" "}
              </button>
            </div>
          </div>
        )}
      </MyContext.Provider>
    </>
  );
}

export default App;
