import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ItemGroupAPI from "../../../API/API_SERVISES/ItemGroup";
import MyContext from "../../MyContext";

const Card = ({ item }) => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    

  // const { item } = props;

  const { imageBaseUrl, itemGroupData } = useContext(MyContext);

  const [subCategory, setSubCategory] = useState([]);

  // console.log(itemGroupData.filter((item) => item.parentGrp2 === 5293))

  const fetchSubCategory = (parentGrp2, value) => {
    // alert(parentGrp2) 5293
    if (parentGrp2 == 0) {
      setIsDropdownOpen(false);
    }
    else {
      setIsDropdownOpen(true);
      // const filteredData = [{
      //   fieldName: "parentGrp2",
      //   operatorName: "Equal",
      //   compareValue: parentGrp2
      // }]
      try {
        // const categoryName = await ItemGroupAPI.GetItemGroupWithFilter(filteredData);
        const categoryName = itemGroupData.filter((item) => item.parentGrp2 === parentGrp2)
        console.log(categoryName)
        const categoryData = categoryName.filter(item => item.showInMenu === true).slice().sort((a, b) => a.displayOrder - b.displayOrder)
        setSubCategory(categoryData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  // console.log(subCategory)
  return (
    <div
      className="card-container"
      onMouseEnter={() => fetchSubCategory(item.d2_Id, true)}
      onMouseLeave={() => fetchSubCategory(0, false)}
    >  {/*style={{ width: "80%" }}*/}
      <Link to={`/productsearch?productId=${item.d2_Id}`} className="card-ecom">
        <img src={`${imageBaseUrl}${item.images[0]}`} alt="Card" className="card-image" />
        <div className="card-caption">{item.printName}</div>
        {isDropdownOpen && (
          <div className="dropdown">
            {/* Dropdown Content */}
            <ul>
              {subCategory && subCategory.map((subData, index) => (
                <Link to={`/productsearch?productId=${subData.d2_Id}`}>
                  <li key={index} className="horizontal-line">
                    <div className="dropdown-content">
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <label><b>{subData.printName}&nbsp;</b></label>
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <label>{subData.nameInHomePage}</label>
                      </div>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
            <label style={{ borderTop: "1px solid #ededed", color: "#37b4c7", marginLeft: "15px", textAlign: "center" }}>View all</label>
          </div>
        )}
      </Link>
    </div >
  );
};

export default Card;
