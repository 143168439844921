import React, { useState } from 'react'
import useIntersectionObserver from '../useIntersectionObserver ';

const SliderImageCard = (props) => {
    const { imageBaseUrl, item } = props;


    const [imageSrc, setImageSrc] = useState('');

    console.log(imageSrc);

    const loadImage = (element) => {
        if (item && item.images) {
            setImageSrc(`${imageBaseUrl}`);
        }
    };

    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });
    return (
        <div
            ref={imageRef}
            className="Img-Trend_Lc Img-Width_Lc Home-Img-slider"
            style={{
                backgroundImage: `url(${imageSrc}${item.images[0]})`,
            }}
        ></div>
    )
}

export default SliderImageCard