import React, { useState, useEffect, useContext } from "react";
import dlt from "../../Assets/Icons/trash.png";
import Order from "../../../API/API_SERVISES/Order";
import AlertBox from "../AlertBox/AlertBox";
import { Link, useNavigate } from "react-router-dom";
import MyContext from "../../MyContext";
import { useLocation } from 'react-router-dom';
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";

const WishListTheme = (props) => {
    const { wishList, setWishList, fetchDataWish, imageBaseUrl } = useContext(MyContext);
    const [alert, setAlert] = useState(false);
    const alertbox = (id) => {
        setAlert(!alert)
    }
    const location = useLocation();
    const { item } = location.state || {};
    const navigate = useNavigate();
    // const [Item, setItem] = useState([])

    // useEffect(() => {
    //     fetchItem()
    // }, [])

    const getProductIdFromUrl = async () => {
        const url = window.location.href;
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1]; // Assuming the ID is at the end of the URL
    };

    // const fetchItem = async () => {
    //     debugger
    //     if (location.state === null && location.state === undefined) {
    //         var itemId = getProductIdFromUrl();
    //         const data = await ItemMaster.GetItemMasterById(itemId);
    //         if (data) {
    //             console.log(data);
    //             setItem(data);
    //         }
    //     }
    // }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteAccountConfirm = async (id) => {

        try {
            const val = await Order.deleteDeleteFavouriteItemList(id);
            if (Boolean(val) === true) {
                setDeleteId(null);
                fetchDataWish();
                alert("Delete data successfully", "Ok");
            } else {
                alert("Error Deleting Data");
            }

        } catch (error) {
            console.error("Error deleting account:", error);
        }
        // fetchData();
    };

    // const navigateBtn = (item) => {
    //     // debugger
    //     if (item.d2ItemId) {
    //         navigate(`/ProductDetailTheme/${item.d2ItemId}`);
    //     } else if (item.d2ItemId) {
    //         window.location.href = item.d2ItemId;
    //     }
    // }



    const { wishLisHandle, setwishLisHandle } = useContext(MyContext)
    const wishListHandleHide = () => {
        setwishLisHandle(false);
        console.log(wishList)
    };


    var isSearch = true;


    return (

        <div class="card-wish-theme3">
            <div onClick={wishListHandleHide} className="card-wish-title-theme3">
                <span>PRODUCTS({wishList?.length || 0})</span>
                <i class="fa-solid fa-xmark wish-list-del-page"></i>
            </div>
            <div class="">
                <div className="favourite-box-container-scroll-theme3">
                    <div className="HeightOverflow" >
                        {wishList.map(item => (
                            <Link to={`/ProductDetailTheme/${item.d2ItemId}`}    state={{ isSearch }}
                                className="favourite-box-admin-WishList-theme3" key={item}>

                                <div className="favourite-head-box-xl" style={{ display: "flex", width: "100%" }}>

                                    <div className="review-box-image2-theme3">
                                        <img className="favourite-img-container-theme3" src={`${imageBaseUrl}${item.itemImgId}`} alt="" />
                                    </div>
                                    <div className="review-box-detail2-theme3">
                                        <div className="review-box-detail-line-admin-1">
                                            <div className="review-box-name21-theme3">
                                                {item.itemName}
                                            </div>
                                            <div onClick={() => DeleteAccountConfirm(item.id)} className="review-box-section-icon-theme3" style={{ cursor: "pointer" }}>
                                                <i onClick={() => DeleteAccountConfirm(item.id)} className="fa-solid fa-xmark wish-list-del-icon"></i>
                                            </div>
                                        </div>
                                        <div className="review-box-detail-line-2">
                                            <div
                                                style={{
                                                    display: "flex", alignItems: "center",
                                                    width: "60px",
                                                }}
                                            >
                                                <p className="review-price">
                                                    <i className="fa-solid fa-indian-rupee-sign"></i> {item.itemPrice}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="wish-list-popup-all-clean">
                    <button className="all-clean-btn-theme3">Clean All</button>
                </div>
            </div>
        </div>

    );
};

export default WishListTheme;
