import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyContext from "../../../MyApp/MyContext";
import Loader1 from "../../Component/Loaders/Loader1";
import "../../Css/Theme3_LC.scss"
import ItemGroup from "../../../API/API_SERVISES/ItemGroup";
import { Link } from "react-router-dom";
import img from "../../Assets/Icons/icon-arrow-left.png"
import img1 from "../../Assets/Icons/icon-arrow-right.png"


const WearTheTrend = () => {


    const [items, setItems] = useState([]);

    const [startIndex, setStartIndex] = useState(0);

    const visibleItems = (items.filter(item => item.showInCollection == true && item.primaryGroup == true && item.images && item.images.length > 0)
        .slice().sort((a, b) => a.displayOrder - b.displayOrder));

    const handleNextClick = () => {
        const visibleItems = items.filter(item => item.showInCollection == true && item.primaryGroup == true && item.images && item.images.length > 0)
            .slice().sort((a, b) => a.displayOrder - b.displayOrder);
        if (startIndex + 4 < visibleItems.length) {
            setStartIndex(startIndex + 4);
        }
    };

    const handlePrevClick = () => {
        if (startIndex - 4 >= 0) {
            setStartIndex(startIndex - 4);
        }
    };

    const [itemsPerPage, setItemsPerPage] = useState(4);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 750px)');

        const handleResize = (e) => {
            if (e.matches) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(4);
            }
        };

        // Set the initial value
        handleResize(mediaQuery);

        // Add event listener
        mediaQuery.addEventListener('change', handleResize);

        // Clean up event listener on component unmount
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }, []);


    var settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const { imageBaseUrl, currentUser, itemGroupData } = useContext(MyContext);
    // console.log(itemGroupData)
    const [loading, setLoading] = useState(false);
    const [productlist, setProductList] = useState([0]);


    useEffect(() => {
        console.log("items", items);
    }, [items]);


    useEffect(() => {
        const fetchData = () => {
            // debugger
            if (currentUser) {
                setLoading(true);
                // try {
                // const categoryName = await ItemGroup.GetItemGroup();
                const categoryName = itemGroupData;
                console.log("categoryName", categoryName)
                // console.log(categoryName)
                const categoryData = categoryName.filter(item => item.showInCollection == true).slice().sort((a, b) => a.displayOrder - b.displayOrder)
                setItems(categoryData)
                setLoading(false);
                // } catch (error) {
                // console.error("Error Fetching Data:", error);
                // }
            }
        };
        fetchData();
    }, [currentUser, itemGroupData])

    // console.log(items);
    const [numItemsToShow, setNumItemsToShow] = useState(4);
    const updateNumItemsToShow = () => {
        if (window.innerWidth < 768) {
            setNumItemsToShow(2);
        } else if (window.innerWidth < 950) {
            setNumItemsToShow(3);
        } else {
            setNumItemsToShow(4);
        }
    };
    useEffect(() => {
        updateNumItemsToShow();
        window.addEventListener('resize', updateNumItemsToShow);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateNumItemsToShow);
    }, []);


    var filteredItems = (items.filter(item => item.showInCollection == true && item.primaryGroup == true && item.images && item.images.length > 0).slice().sort((a, b) => a.displayOrder - b.displayOrder));
    console.log("filteredItems", filteredItems);
    console.log("Just Items", items);

    return (
        <div>
            <div className="Flex-WearTrend_Lc">
                <div className="WearThe_theme3_Lc">{items != null && items != undefined && items.length > 0 && items[0].nameInHomePage}
                    {/* <div className="Trend-MarginTop_Lc">TREND</div> */}
                    {/* <div className="Trend-Hottest-Tag-Lc">Our hottest collection</div> */}
                </div>
                <div className="Flex_Slide1_Lc">
                    <img className="LeftArrow_Lc" src={img} onClick={handlePrevClick} alt="Previous" />
                    {items.filter(item => item.showInCollection == true && item.primaryGroup == true && item.images && item.images.length > 0).slice(startIndex, startIndex + numItemsToShow)
                        .slice().sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((item) => (
                            <Link to={`/productsearch?productId=${item.d2_Id}`} key={item.id} className="Flex-Explore_Lc">
                                {/* {item.images && item.images.length > 0 && ( */}
                                {/* <img className="Img-Trend_Lc ExploreImg-width" src={`${imageBaseUrl}${item.images[0]}`} alt="" /> */}
                                <div
                                    className="Img-Trend_Lc1 ExploreImg-width"
                                    style={{
                                        backgroundImage: `url(${imageBaseUrl}${item.images[0]})`,
                                        backgroundSize: '100%',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        // height: '100%',
                                        backgroundRepeat: "no-repeat"
                                    }}
                                    alt=""
                                ></div>
                                {/* )}  */}
                                <div className="Trend-Title_Lc">{item.menuName}</div>
                                <div className="Trend-Explore_Lc">Explore</div>
                            </Link>
                        ))}

                    <img className="Right-Arrow_Lc" src={img1} onClick={handleNextClick} alt="Next" />
                </div>
                {loading && <Loader1 />}
            </div>
        </div>
    );
};

export default WearTheTrend;
