import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from '../../MyContext';
import useIntersectionObserver from '../useIntersectionObserver ';


const NameWithBanner_Lc = (props) => {

    const { imageBaseUrl } = useContext(MyContext);
    const { item } = props;
    console.log("itemm", item.bannerCaption)

    const [imageSrc, setImageSrc] = useState('');

    console.log(imageSrc);

    const loadImage = (element) => {
        if (item && item.bannerImageId) {
            setImageSrc(`${imageBaseUrl}`);
        }
    };

    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

    const navigate = useNavigate();

    const navigateBtn = () => {
        if (item.redirectTo != null && item.redirectTo != undefined && item.redirectTo != 0) {
            navigate(`/productsearch?productId=${item.redirectTo}`);
        }
        else if (item.redirectToURL != null && item.redirectToURL != undefined && item.redirectToURL != "") {
            window.location.href(item.redirectToURL)
        }
    }

    return (
        <div>
            <div onClick={navigateBtn}>
                <span style={{ display: item.bannerCaption == "" ? "none" : "block" }}>
                    <div style={{ textAlign: "-webkit-center" }}>
                        <h4 className="Title-Name_Lc">{item.bannerCaption}</h4>
                    </div>
                </span>
                <div>
                    <img ref={imageRef} className='banner-1-style' src={`${imageSrc}${item.bannerImageId}`} alt="" />
                </div>
            </div>
        </div>
    )
}

export default NameWithBanner_Lc;