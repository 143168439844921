import React, { useContext, useEffect, useState, useRef } from "react";
import styletype from "../../Assets/Icons/glasses (1).png";
import styletype1 from "../../Assets/Icons/glasses.png";
import styletype2 from "../../Assets/Icons/hexa.png";
import styletype3 from "../../Assets/Icons/oval-shape-reading-eyeglasses.png";
import styletype4 from "../../Assets/Icons/Square.webp";
import styletype5 from "../../Assets/Icons/Wayfarer.webp";
import styletype6 from "../../Assets/Icons/Rectangle.webp";
import styletype7 from "../../Assets/Icons/sun-glasses.png";
import styletype8 from "../../Assets/Icons/Geometric.webp";
import styletype9 from "../../Assets/Icons/Clubmaster.webp";
import styletype10 from "../../Assets/Icons/Sports.webp";
import { useSpring, animated } from "react-spring";
import grid from "../../Assets/Icons/list.png";
import grid1 from "../../Assets/Icons/visualization.png";
import MobileFilterPage from "../../Component/Modal/MobileFilterPage";
import ProductCart1 from "../../Component/Card/ProductCart1";
// import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster";
import Loader1 from "../../Component/Loaders/Loader1";
//import img from "../../Assets/Images/pic7.png";
import ProductCart2 from "../../Component/Card/ProductCart2";
import ProductCart3 from "../../Component/Card/ProductCart3";
import ProductCart4 from "../../Component/Card/ProductCart4";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster";
import ProductCart from "../../Component/Card/ProductCart";
import img from "../../Assets/Images/category.webp";
import { useLocation } from 'react-router-dom';

import MultiRangeSlider from "./MultiRangeSlider";
import MyContext from "../../MyContext";
import bannerImg from "../../Assets/Image_lc/ContectLenses/desktop-plp-vc-eye.webp"
import ProductCart11 from "../../Component/Card/ProductCart11";
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";

const ProductSearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [price, setPrice] = useState(false);
  const [shopFor, setShopFor] = useState(false);
  const [color, setColor] = useState(false);
  const [style, setStyle] = useState(false);
  const [shape, setShape] = useState(true);
  const [size, setSize] = useState(false);
  const [material, setMaterial] = useState(false);
  const [changegrid, setChangegrid] = useState(false);

  const [loading, setLoading] = useState(false);
  // const [productlist, setProductList] = useState([]);
  const [sortOrder, setSortOrder] = useState('');
  const [mobileFilter, setMobileFilter] = useState(false);
  const [filterProducts, setFilterProducts] = useState(false);
  const [filteredata2, setFilteredData2] = useState([]);
  const [openAttributes, setOpenAttributes] = useState({});
  const [apiBannerData, setApiBannerData] = useState([]);
  // console.log(filteredata2)
  const [activeAttrId, setActiveAttrId] = useState([]);

  const { productSearch, setProductSearch, searchName, setSearchName, productlist, setProductList, attributeList, setAttributeList, imageBaseUrl, currentUser, shopBanner } = useContext(MyContext)
  const [maxSalePrice, setMaxSalePrice] = useState(0);
  console.log("maxsale price", maxSalePrice);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const productId = query.get('productId');


  console.log("loading", loading);
  useEffect(() => {
    if (productId) {
      fetchData2(productId);
    }
  }, [productId]);

  const fetchData2 = async () => {
    setLoading(true)
    try {
      const val = await ItemMasterApi.GetEcomItemFilterFromCategory(productId);
      console.log("This is Ecom", val)
      setAttributeList(val);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)

    }
  };

  // useEffect(() => {
  //   const filterProductsByEcomCategory = (productList, productId) => {
  //     debugger
  //     return productList.filter(product => product.ecomCategoryDetail.includes(productId));
  //   };
  //   const filteredProducts = filterProductsByEcomCategory(productlist, productId);

  //   console.log(filteredProducts);
  // }, [productId])


  useEffect(() => {

    const maxfunc = async () => {
      debugger
      if (productlist.length > 0) {
        const maxPrice = await productlist.reduce(
          (max, product) => Math.max(max, product.salePrice),
          -Infinity
        );
        setMaxSalePrice(maxPrice + 50);
      }
    }
    maxfunc()
  }, [productId]);



  const innerScrollerRef = useRef(null);

  const handleInnerScroll = () => {
    const innerScroller = innerScrollerRef.current;
    const { scrollTop, scrollHeight, clientHeight } = innerScroller;

    if (scrollTop + clientHeight >= scrollHeight) {
      // Inner scroller is at the bottom
      // Optionally, handle some logic when the inner scroller reaches the bottom
    }
  };


  // debugger

  // Function to handle scroll event
  // const handleScroll = () => {
  //   // Update scroll position state
  //   // console.log(window.scrollY);
  //   if (window.scrollY > 130) {
  //     document.querySelector(".ProductSearch-right .productSearch-section-wrap3").style.overflow = "scroll";
  //   } else {
  //     document.querySelector(".ProductSearch-right .productSearch-section-wrap3").style.overflow = "unset";
  //   }
  // };

  const showoption4 = () => {
    setShape(!shape);

  };

  //************************Scroll*********************************
  // useEffect(() => {
  //   // Add scroll event listener when component mounts
  //   window.addEventListener('scroll', handleScroll);

  //   // Remove scroll event listener when component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // const [selectedPriceRange, setSelectedPriceRange] = useState({
  //   min: 0,
  //   max: 5000
  // });


  console.log(maxSalePrice)

  // useEffect(() => {
  //   alert(maxSalePrice)
  // }, [currentUser, maxSalePrice])

  console.log(productlist);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);




  useEffect(() => {
    setMaxVal(maxSalePrice);
  }, [maxSalePrice]);


  //******************* Filter ************************* */

  const fetchDataFilter = async () => {
    setLoading(true);
    if (maxVal !== 0) {
      const filteredata = [
        // {
        //   fieldName: "isActive",
        //   operatorName: "Equal",
        //   compareValue: true
        // },

        {
          fieldName: "salePrice",
          operatorName: "lessthan",
          compareValue: maxVal
        },
        {
          fieldName: "salePrice",
          operatorName: "greaterthan",
          compareValue: minVal
        },

      ]
      try {
        const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
        setProductList(categoryName)
        // setMaxSalePrice(0)
        setLoading(false);
        console.log("Search", searchName)
      } catch (error) {
        setLoading(false);
        console.error("Error Fetching Data:", error);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (productId != null) {

      fetchDataFilter()
    }
  }, [minVal, maxVal,searchName]);



  useEffect(() => {
    if (sortOrder !== '') {
      if (sortOrder === 'newlyAdded') {
        // If sorting by newly added, fetch products from API in descending order
        fetchSortedProducts();
      } else {
        sortProducts(sortOrder);
      }
    }
  }, [sortOrder]);

  const sortProducts = (sortMethod) => {
    const sortedProducts = [...productlist].sort((a, b) => {
      switch (sortMethod) {
        case 'priceLowToHigh':
          return a.salePrice - b.salePrice;
        case 'priceHighToLow':
          return b.salePrice - a.salePrice;
        case 'newlyAdded':
          return new Date(b.addedAt) - new Date(a.addedAt);
        default:
          return 0;
      }
    });
    setProductList(sortedProducts);
  };

  const filteredata = [
    {
      fieldName: "parentGrp",
      operatorName: "Equal",
      compareValue: productId
    }
  ]
  const fetchSortedProducts = async () => {
    setLoading(true)
    try {
      const response = await ItemMasterApi.GetItemMasterDescendingOrder(filteredata);
      const sortedProducts = response.data.sort((a, b) => {
        return new Date(b.addedAt) - new Date(a.addedAt);
      });
      setProductList(sortedProducts);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching sorted products:', error);
      setLoading(false)
    }
    setLoading(false)
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  //**************************Search Name*************************** */

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const filteredata = [
  //       {
  //         fieldName: "isActive",
  //         operatorName: "Equal",
  //         compareValue: true
  //       },
  //       {
  //         fieldName: "name",
  //         operatorName: "equal",
  //         compareValue: searchName
  //       }
  //     ]
  //     try {
  //       const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
  //       setProductList(categoryName)
  //       // setProductSearch(categoryName)
  //       setLoading(false);
  //       console.log("Search", searchName)
  //     } catch (error) {
  //       console.error("Error Fetching Data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [searchName]);

  //***************************************************** */

  // const fetchData1 = async () => {
  //   try {
  //     const val = await ItemMasterApi.getGetItemAttributeSettings();
  //     console.log(val);
  //     setAttributeList(val);
  //     console.log("This is the attribute", attributeList)
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };


  const changeprductgrid = () => {
    setChangegrid(!changegrid);
    const elements = document.getElementsByClassName("card-product");

    const elements1 = document.getElementsByClassName("Card-product-type-1");
    for (const element of elements) {
      element.classList.toggle("cart-product-for-two-row");
    }

    for (const element1 of elements1) {
      element1.classList.toggle("cart-product-for-two-row");
    }
  };


  const fadeAnimation = useSpring({
    opacity: price ? 1 : 0,
    height: price ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation1 = useSpring({
    opacity: shopFor ? 1 : 0,
    height: shopFor ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation2 = useSpring({
    opacity: color ? 1 : 0,
    height: color ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation3 = useSpring({
    opacity: style ? 1 : 0,
    height: style ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation5 = useSpring({
    opacity: size ? 1 : 0,
    height: size ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation6 = useSpring({
    opacity: material ? 1 : 0,
    height: material ? 80 : 0,
    config: { duration: 200 },
  });


  // const toggleOption = (attributeName) => {
  //   setOpenAttributes(prevState => ({
  //     ...prevState,
  //     [attributeName]: !prevState[attributeName]
  //   }));
  // };

  const showoption = () => {
    setPrice(!price);
  };

  const openmobilefilter = () => {
    setMobileFilter(!mobileFilter);
  };

  //************************************************************ */
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const filteredata1 = [
  //       {
  //         fieldName: "string",
  //         operatorName: "string",
  //         compareValue: "string"
  //       }
  //     ]
  //     const filteredata3 = filteredata2.map(item => ({
  //       parentId: item.attributeId, // Assuming parentId should be attributeId
  //       attrId: item.attrId
  //     }));
  //     try {
  //       const filteredata = {
  //         filter: filteredata1,
  //         attribute: filteredata3,
  //         pageNumber: 0,
  //         pageSize: 50000
  //       };

  //       const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
  //       console.log(categoryName)
  //       setFilterProducts(categoryName, productSearch)
  //       setProductList(categoryName, productSearch)
  //       setLoading(false);
  //       console.log("Search", searchName)
  //     } catch (error) {
  //       console.error("Error Fetching Data:", error);
  //     }
  //   };

  // }, []);



  // ******************Filter product on the click of attribute***************************
  const handleCheckboxChange12 = async (attrId, attributeId, checked) => {
    setLoading(true);

    let updatedValues;

    if (checked) {
      // If checked, add the filter to filteredData2
      setFilteredData2(prevFilteredData2 => [
        ...prevFilteredData2,
        {
          parentId: attributeId,
          attrId: attrId,
        }
      ]);
      updatedValues = [
        ...filteredata2,
        {
          "parentId": attributeId,
          "attrId": attrId,
        }
      ];
    } else {
      // If unchecked, remove the filter from filteredData2
      setFilteredData2(prevFilteredData2 => prevFilteredData2.filter(filter => !(filter.parentId === attributeId && filter.attrId === attrId)));

      // Remove the filter from updatedValues
      updatedValues = filteredata2.filter(filter => !(filter.parentId === attributeId && filter.attrId === attrId));
    }
    const filteredata1 = [
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string"
      }
    ];

    try {
      const filteredata = {
        filter: filteredata1,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        attribute: updatedValues,
        pageNumber: 0,
        pageSize: 50000
      };

      console.log(filteredata);

      const categoryName = await ItemMasterApi.GetItemMasterFilterAttribute(filteredata);
      setFilterProducts(categoryName);
      setProductList(categoryName);
    } catch (error) {
      console.error("Error Fetching Data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }

  };

  const [visibleItems, setVisibleItems] = useState(4);
  const defaultVisibleCount = 6;

  const toggleOption = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: prevState[attributeName] === defaultVisibleCount ? null : defaultVisibleCount
    }));
  };

  const handleMoreClick = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: prevState[attributeName] + defaultVisibleCount
    }));
  };

  const handleHideClick = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: defaultVisibleCount
    }));
  };

  const fetchAllBannerdata = () => {

    if (currentUser) {
      try {
        // const response = await ShopBanner.GetShopBanners()
        const response = shopBanner;
        // const response = await ShopBanner.GetAllImageBannerName()
        if (response) {
          console.log("Response", response);
          setApiBannerData(response)
        }
      } catch (error) {
        alert(error)
        throw new error("Unable To fetch Data")
      }
    }
  }
  useEffect(() => {
    fetchAllBannerdata()
  }, [currentUser, shopBanner])

  const ImageId = apiBannerData.filter(item => item.showInCategoryGroup == productId).map(image => image.bannerImageId)
  console.log(ImageId);
  // console.log(attributeList.map((attribute) => (attribute.attributeChilds.map((child) => { return (child) }))))



  const [parentId, setParentId] = useState(null);
  const [childAttrId, setChildAttrId] = useState(null);
  const [attributes, setAttributes] = useState([]);

  console.log(attributeList)

  const handleParentClick = (parentId) => {
    setParentId(parentId);
    console.log("Parent ID:", parentId);

  };


  const handleChildClick = async (attrId) => {
    setLoading(true);
    // debugger
    setActiveAttrId((prevActiveAttrIds) => {
      if (prevActiveAttrIds.includes(attrId)) {
        return prevActiveAttrIds.filter(id => id !== attrId);
      } else {
        return [...prevActiveAttrIds, attrId];
      }
    });

    setChildAttrId(attrId);
    console.log("Child Attr ID:", attrId);

    let updatedValues;

    if (activeAttrId.includes(attrId)) {
      // If !active, remove the attribute
      updatedValues = activeAttrId.filter(id => id !== attrId).map(id => ({
        parentId,
        attrId: id,
      }));
    } else {
      // If active, add the attribute
      updatedValues = [
        ...activeAttrId.map(id => ({
          parentId,
          attrId: id,
        })),
        {
          parentId,
          attrId,
        }
      ];
    }

    const filteredata1 = [
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string"
      }
    ];

    try {
      const filteredata = {
        filter: filteredata1,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        attribute: updatedValues,
        pageNumber: 0,
        pageSize: 50000
      };

      console.log(filteredata);

      const response = await ItemMasterApi.GetItemMasterFilterAttribute(filteredata);
      if (response) {
        setProductList(response);
      }
    } catch (error) {
      console.error('Error fetching filtered data:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <img style={{
        height: (ImageId.length === 0) ? "0" : "300px"
      }
      } className="Category-banner-img" src={`${imageBaseUrl}${ImageId[0]}`} alt=""></img>

      <div className="ProductSearch">
        <div className="ProductSearch-left">
          <div className="ProductSearch-top">
            <p>Filters</p>
          </div>
          <div className="productsearch-bottom">

            {/* <div className="filter-heading" onClick={showoption4}>
              {" "}
              Shape
              {shape ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {shape && (
              <animated.div className="filter-options">
                <div className="style-filter-box-wrap">
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image"
                      src={styletype}
                      alt=""
                    />{" "}
                    <p>ROUND</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image"
                      src={styletype1}
                      alt=""
                    />{" "}
                    <p>CATEYE</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image"
                      src={styletype2}
                      alt=""
                    />{" "}
                    <p>HEXAGONAL</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image"
                      src={styletype3}
                      alt=""
                    />{" "}
                    <p>OVAL</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image-1"
                      src={styletype4}
                      alt=""
                    />{" "}
                    <p>SQUARE</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image-1"
                      src={styletype5}
                      alt=""
                    />{" "}
                    <p>WAYFARER</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image-1"
                      src={styletype6}
                      alt=""
                    />{" "}
                    <p>RECTANGLE</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image"
                      src={styletype7}
                      alt=""
                    />{" "}
                    <p>AVIATOR</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image-1"
                      src={styletype8}
                      alt=""
                    />{" "}
                    <p>GEOMATRIC</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image-1"
                      src={styletype9}
                      alt=""
                    />{" "}
                    <p>CLUBMASTER</p>
                  </div>
                  <div className="style-filter-1">
                    {" "}
                    <img
                      className="filter-type-image-1"
                      src={styletype10}
                      alt=""
                    />{" "}
                    <p>SPORTS</p>
                  </div>
                </div>
              </animated.div>
            )} */}


            <div className="filter-heading" onClick={showoption}>
              Price-Range
              {price ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {price && (
              <animated.div style={fadeAnimation} className="filter-options">
                {" "}
                <MultiRangeSlider
                  min={0}
                  max={maxSalePrice}
                  handleCheckboxChange
                  minVal={minVal}
                  setMinVal={setMinVal}
                  maxVal={maxVal}
                  setMaxVal={setMaxVal}
                  onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}

                />
              </animated.div>
            )}

            {Array.isArray(attributeList) && attributeList.map(attribute => (
              <React.Fragment key={attribute.attributeName}>
                <div className="filter-heading" onClick={() => { toggleOption(attribute.attributeName); handleParentClick(attribute.attributeId); }}>
                  {attribute.attributeName}
                  {visibleItems[attribute.attributeName] ? (
                    <i className="fa-solid fa-caret-up option-icon1"></i>
                  ) : (
                    <i className="fa-solid fa-sort-down option-icon"></i>
                  )}
                </div>
                {/* <div className="filter-options"> */}
                {visibleItems[attribute.attributeName] && (
                  // <React.Fragment>
                  //   {attribute.attributeChilds.slice(0, visibleItems[attribute.attributeName]).map(child => (
                  //     <div key={child.attrId} className="filter-options">
                  //       <div className="filter-line">

                  //         {!child.imageId && (
                  //           <input
                  //             type="checkbox"
                  //             className="ui-checkbox"
                  //             value={`${child.attrId},${attribute.attributeId}`}
                  //             checked={filteredata2[`${child.attrId},${attribute.attributeId}`]}
                  //             onChange={() => handleCheckboxChange12(
                  //               child.attrId,
                  //               attribute.attributeId,
                  //               !filteredata2.some(filter => filter.parentId === attribute.attributeId && filter.attrId === child.attrId)
                  //             )}
                  //           />)}
                  //         {attribute.attributeName.toLowerCase() === 'color' && (
                  //           <div style={{ background: child.attrName }} className="filterclrset"></div>
                  //         )}
                  //         {child.imageId ? (

                  //           <animated.div className="filter-options">
                  //             <div className="style-filter-box-wrap">
                  //               <div className="style-filter-1">
                  //                 <img
                  //                   className="filter-type-image"
                  //                   src={`${imageBaseUrl}${child.imageId}`}
                  //                   alt=""
                  //                 />
                  //                 <p>{child.attrName}</p>
                  //               </div>
                  //             </div>
                  //           </animated.div>
                  //         ) : (
                  //           <p style={{ margin: "0" }}>{child.attrName}</p>
                  //         )}
                  //       </div>
                  //     </div>
                  //   ))}
                  //   {attribute.attributeChilds.length > visibleItems[attribute.attributeName] && (
                  //     <p
                  //       style={{ textAlign: "center", color: "blueviolet", cursor: "pointer" }}
                  //       onClick={() => handleMoreClick(attribute.attributeName)}
                  //     >
                  //       {attribute.attributeChilds.length - visibleItems[attribute.attributeName]} More
                  //     </p>
                  //   )}
                  //   {visibleItems[attribute.attributeName] > defaultVisibleCount && (
                  //     <p
                  //       style={{ textAlign: "center", color: "blueviolet", cursor: "pointer" }}
                  //       onClick={() => handleHideClick(attribute.attributeName)}
                  //     >
                  //       Hide
                  //     </p>
                  //   )}
                  // </React.Fragment>



                  <React.Fragment>
                    {/* Items with images */}
                    <div className="filter-options">
                      <div className="filter-line">
                        <animated.div className="filter-options">
                          <div className="style-filter-box-wrap">
                            {attribute.attributeChildren
                              .filter(child => child.imageId)
                              .slice(0, visibleItems[attribute.attributeName])
                              .map(child => (

                                <div key={child.attrId} className={`style-filter-1 ${activeAttrId.includes(child.attrId) ? 'style-filter-1-active' : 'style-filter-1'}`}
                                  onClick={() => handleChildClick(child.attrId)}>
                                  {/* <img
                                      className="filter-type-image"
                                      src={`${imageBaseUrl}${child.imageId}`}
                                      alt=""
                                    /> */}
                                  <div
                                    className="filter-type-image"
                                    style={{
                                      backgroundImage: `url(${imageBaseUrl}${child.imageId})`,
                                      // width: '100px',
                                      height: '40px',
                                      backgroundSize: '85%',  // in case of lens2cart
                                      // backgroundSize: '50%',  //in case of pintoo mobile
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",

                                    }}
                                  />
                                  <p style={{ marginTop: "1px" }}>{child.attrName}</p>
                                </div>
                              ))}
                          </div>
                        </animated.div>
                      </div>
                    </div>


                    {/* Items without images */}
                    {attribute.attributeChildren
                      .filter(child => !child.imageId)
                      .slice(0, visibleItems[attribute.attributeName])
                      .map(child => (
                        <div key={child.attrId} className="filter-options">
                          <div className="filter-line">
                            <input
                              type="checkbox"
                              className="ui-checkbox"
                              value={`${child.attrId},${attribute.attributeId}`}
                              checked={filteredata2[`${child.attrId},${attribute.attributeId}`]}
                              onChange={() => handleCheckboxChange12(
                                child.attrId,
                                attribute.attributeId,
                                !filteredata2.some(filter => filter.parentId === attribute.attributeId && filter.attrId === child.attrId)
                              )}
                            />
                            {attribute.attributeName.toLowerCase() === 'color' && (
                              <div style={{ background: child.attrName }} className="filterclrset"></div>
                            )}
                            <p style={{ margin: "0" }}>{child.attrName}</p>
                          </div>
                        </div>
                      ))}

                    {/* "More" and "Hide" buttons */}
                    {attribute.attributeChildren.length > visibleItems[attribute.attributeName] && (
                      <p
                        style={{ textAlign: "center", color: "blueviolet", cursor: "pointer" }}
                        onClick={() => handleMoreClick(attribute.attributeName)}
                      >
                        {attribute.attributeChildren.length - visibleItems[attribute.attributeName]} More
                      </p>
                    )}
                    {visibleItems[attribute.attributeName] > defaultVisibleCount && (
                      <p
                        style={{ textAlign: "center", color: "blueviolet", cursor: "pointer" }}
                        onClick={() => handleHideClick(attribute.attributeName)}
                      >
                        Hide
                      </p>
                    )}
                  </React.Fragment>


                )}
                {/* </div> */}
              </React.Fragment>
            ))}




            { /* <div className="filter-heading" onClick={showoption2}>
              {" "}
              Color
              {color ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {color && (
              <animated.div style={fadeAnimation2} className="filter-options">
                {attributeList.map((attributeChilds, index) => (
                  <div className="filter-line" key={index}>
                    <input type="checkbox" className="ui-checkbox" id={attributeChilds.attrId} name={attributeChilds.attrName} />
                    <label style={{ color: "red" }}>{attributeChilds.attrName}</label>
                  </div>
                ))}
              </animated.div>
            )}

            <div className="filter-heading" onClick={showoption3}>
              Styles
              {style ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {style && (
              <animated.div style={fadeAnimation3} className="filter-options">
                {" "}
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
              </animated.div>
            )}

            <div className="filter-heading" onClick={showoption5}>
              {" "}
              Size
              {size ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {size && (
              <animated.div style={fadeAnimation5} className="filter-options">
                {" "}
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
              </animated.div>
            )}

            <div className="filter-heading" onClick={showoption6}>
              {" "}
              Material
              {material ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {material && (
              <animated.div style={fadeAnimation6} className="filter-options">
                {" "}
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
              </animated.div>
            )} */}
          </div>
        </div>
        <div className="ProductSearch-right">
          <div className="ProductSearch-top">
            <p>Products</p>
            {changegrid ? (
              <img
                onClick={changeprductgrid}
                className="product-grid-btn"
                src={grid}
                alt=""
              />
            ) : (
              <img
                onClick={changeprductgrid}
                className="product-grid-btn"
                src={grid1}
                alt=""
              />
            )}
            {/* <div>SORT BY </div> */}
            <select onChange={handleSortChange} name="" id="" className="filter-select">
              <option value="">Sort by</option>
              {/* <option value="">Most populer</option> */}
              <option value="priceLowToHigh">Price low to high</option>
              <option value="priceHighToLow">Price high to low </option>
              {/* <option value="">Customer Reviews</option> */}
              {/* <option value="newlyAdded">Newly Added</option> */}
            </select>
          </div>
          <div className="productSearch-section-wrap3"
            ref={innerScrollerRef}
            onScroll={handleInnerScroll}
          >

            {productlist
              .filter(item =>
                (productId === null || productId === undefined || item.ecomCategoryDetail.includes(Number(productId)))
                && item.published !== false
              )
              .map(item => (
                <ProductCart11
                  key={item.id}
                  item={item}
                />
              ))}


            {/* {productlist
              .map((item) => (
                <ProductCart11
                  item={item}
                />
              ))} */}

            {/* {productlist
              .map((item) => (
                <ProductCart1
                  item={item}
                />
              ))}

            {productlist
              .map((item) => (
                <ProductCart2
                  item={item}
                />
              ))}

            {productlist
              .map((item) => (
                <ProductCart3
                  item={item}
                />
              ))}

            {productlist
              .map((item) => (
                <ProductCart4
                  item={item}
                />
              ))}

            {productlist
              .map((item) => (
                <ProductCart
                  item={item}
                />
              ))} */}
          </div>
        </div>

        {mobileFilter && <MobileFilterPage close={openmobilefilter} />}

        <button onClick={openmobilefilter} className="filter-mobile-btn">
          {" "}
          <i
            style={{ marginRight: "10px" }}
            className="fa-solid fa-filter"
          ></i>{" "}
          Filter{" "}
        </button>
        {loading && <Loader1 />}
      </div>
    </>
  );
};

export default ProductSearch;
