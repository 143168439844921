import React, { useState, useEffect, useContext } from "react";
import logo_lc from "../../Assets/Images/logo.jpg";
import Theme3NavbarOnhover from "./NavbarHoverPart/Theme3NavbarOnhover";
import { Link, useLocation } from 'react-router-dom';
import MyContext from "../../MyContext";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import Loader1 from "../Loaders/Loader1";
import ItemGroupAPI from "../../../API/API_SERVISES/ItemGroup";
import CompanyApi from "../../../API/API_SERVISES/Company";
import Logo from "./../../Assets/Image_lc/logo.jpg";
import NavBarMobile1 from "../NavBarMobile/NavBarMobile1";
import NavBarMobile_Lc from "../NavBarMobile/NavBarMobile_Lc";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import SearchItemBox from "../SearchItemBox/SearchItemBox";


const Navbar_lc = (props) => {
  const { wishList } = props
  // console.log("444444444444444444444444444444", wishList);
  const [close, setClose] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [parentId, setParentId] = useState();
  const [LcNavbar, setLcNavbar] = useState(true);
  // const [companyData, setCompanyData] = useState("")
  const { myaccountmodal, userArray, modal, setModal, setLoginPanel, totalQuantity, productlist, setProductList, currentUser,
    wishLisHandle, setwishLisHandle, logoCompanyUrl, companyData, setCompanyData, imageBaseUrl, itemGroupData } = useContext(MyContext);

  const location = useLocation();
  const currentPath = location.pathname;
  const { totalPrice2 } = location.state || {};

  const [Data, setData] = useState([]);



  const openNavbarLc = async (d1_Id) => {
    // alert(d1_Id)
    setParentId(d1_Id)
    setLcNavbar(true);
  };



  const wishListHandleShow = () => {
    setwishLisHandle(!wishLisHandle);
  };

  const closeNavbarLc = async () => {
    setLcNavbar(false);
    // alert("close")
  };

  const signin = () => {
    setLoginPanel(!modal);
  };

  const [userData, setUserData] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  useEffect(() => {
    const data = localStorage.getItem("accountLogin");
    const parsedData = JSON.parse(data);
    setUserData(parsedData);
  }, []);

  const [activeLink, setActiveLink] = useState("/");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const fetchData = () => {
    // // debugger
    if (currentUser) {

      try {
        // const categoryName = await ItemGroupAPI.GetItemGroup();
        const categoryName = itemGroupData;
        // console.log(categoryName);
        const categoryData = categoryName.filter(item => item.imageShowOnError == 1).slice().sort((a, b) => a.displayOrder - b.displayOrder)
        setCategoryData(categoryData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    // setLoading(true);
    fetchData();
  }, [currentUser, itemGroupData]);

  const [buttonColors, setButtonColors] = useState({
    defaultColor: "",
    ourMenuColor: "",
    myCartColor: "",

  });

  // const fetchData2 = async () => {
  //   // debugger
  //   if (currentUser) {

  //     try {
  //       const Data = await CompanyApi.GetCompanyById(id);
  //       // console.log(categoryName);
  //       setUserCompany(Data);
  //       console.log(Data);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   setLoading(false);
  // };
  // useEffect(() => {
  //   // setLoading(true);
  //   fetchData2();
  // }, [currentUser]);



  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const searchValueFunction = async (e) => {
    // navigate("/productsearch")
    if (currentUser) {
      try {
        const value = e.target.value;
        setSearchValue(value);
        // console.log(value);
        const filteredData = [
          {
            fieldName: "name",
            operatorName: "contains",
            compareValue: value,
          },
        ];
        // const searchItem = await ItemMaster.GetItemMasterFilter(filteredData);
        const searchItem = await ItemMaster.GetItemMasterFilter(filteredData, 15);
        if (searchItem) {
          console.log(searchItem);
          const filteredItems = searchItem.filter(item => item.attributeParentId != 0);
          console.log(filteredItems);
          setData(filteredItems)
        }
      } catch (error) {
        console.error('Error in searchValueFunction:', error);
      }
    }
  };
  useEffect(() => {
    searchValueFunction()
  }, [currentUser])

  const closeSuggestion = () => {
    setTimeout(() => {
      setClose(false);
    }, 100); // 1000 milliseconds = 1 second
  }

  const openSuggestion = () => {
    setClose(true)
  }


  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (currentUser) {
  //       try {

  //         const data = await CompanyApi.GetCompany();
  //         const data1 = await CompanyApi.GetCompanyById(data[0].id)
  //         console.log(data1);
  //         setCompanyData(data1);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [currentUser]);


  const showButton = () => {
    if (currentPath === '/Cart3') {
      return (
        <div >
          <div className="navbar_theme_3">

            <div className={`navabar_middle_theme_33 ${buttonColors.defaultColor}`}>
              <div className="navabar_middle_theme_3-logo-box">
                <Link to="/" className="Logo_theme_3_box">
                  <img className="logo_theme_3-secondary" src={`${imageBaseUrl}${companyData.companyImageId}`} alt="" />
                  {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}
                </Link>
                <div className="mobileNo_Theme_lc">

                </div>
              </div>
              <div className="Theme_3_navbar_Buttons">
              </div>
            </div>
            <div className={`navbar_bottom_theme_3 ${buttonColors.defaultColor}`}>
            </div>
          </div>
        </div>
      );
      // } if (currentPath === '/ShippingAddress') {
      //   return (
      //     <div >
      //       <div className="navbar_theme_3">
      //         <div className={`navabar_middle_theme_33 ${buttonColors.defaultColor}`}>
      //           <div className="navabar_middle_theme_3-logo-box">
      //             <Link to="/" className="Logo_theme_3_box">
      //               <img className="logo_theme_3-secondary" src={`${imageBaseUrl}${companyData.companyImageId}`} alt="" />
      //               {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}
      //             </Link>
      //             <div className="mobileNo_Theme_lc">
      //             </div>
      //           </div>
      //           <div className="Theme_3_navbar_Buttons">
      //           </div>
      //         </div>
      //         <div className={`navbar_bottom_theme_3 ${buttonColors.defaultColor}`}>
      //         </div>
      //       </div>
      //     </div>
      //   );
    }
    else if (currentPath === '/Checkout') {
      return (
        <div >

        </div>
      );
    }
    else {
      return (
        <div>
          <div className="navbar_theme_3">
            {/* <div>
              <p className="Head-top-contect">Contact Us</p>
            </div> */}

            <div className={`navabar_middle_theme_3 ${buttonColors.defaultColor}`}>
              <div className="navabar_middle_theme_3-logo-box">
                <Link to="/" className="Logo_theme_3_box">
                  <img className="logo_theme_3" src={`${imageBaseUrl}${companyData?.companyImageId}`} alt="" />
                  {/* <img className="logo_theme_3-secondary" src={Logo} alt="" /> */}

                </Link>
                <div className="mobileNo_Theme_lc">
                  <i className="fa-solid fa-phone-volume"></i>
                  <p>{companyData.mobileNo}</p>
                </div>
              </div>


              <div className="div-for-search-input">
                <input
                  className="search_box_theme_3"
                  placeholder="What are you looking for?"
                  type="text"
                  value={searchValue}
                  onBlur={closeSuggestion}
                  onChange={searchValueFunction}
                  onFocus={openSuggestion}
                />
                {searchValue.length != 0 && window.location.pathname != "productsearch?productId=582" &&
                  <SearchItemBox searchValue={searchValue} Data={Data} close={close} setClose={setClose} setSearchValue={setSearchValue} />
                }
              </div>


              <div className="Theme_3_navbar_Buttons">
                {/* <div>Track Order</div> */}
                <div
                  onClick={myaccountmodal}
                  className={`navbar-option ${activeLink === "/ShippingAddress" ? "active" : ""
                    }`}
                >
                  <div onClick={signin} >{userData ? userData.name : "Sign in & Sign Up"}</div>
                </div>
                <div onClick={wishListHandleShow} >
                  <i style={{ position: "relative" }} className="fa-regular fa-heart"><span className="Qty-ttl">{wishList?.length || 0}</span></i> Wishlist
                </div>
                <Link to="/Cart3" className="navbar-cart-link-theme3">

                  <i style={{ position: "relative" }} className="fa-solid fa-bag-shopping"><span className="Qty-ttl">{totalQuantity} </span></i> Cart{" "}
                  {/* {totalQuantity} */}
                </Link>
              </div>
            </div>

            <div className={`navbar_bottom_theme_3 ${buttonColors.defaultColor}`}>
              <div className="navbar_Bottom_left_theme_3">
                {CategoryData.filter(
                  (category1) => category1.primaryGroup === true && category1.showInMenu === true && category1.imageShowOnError == 1
                )
                  .slice().sort((a, b) => a.displayOrder - b.displayOrder)
                  .map((category) => (
                    <div key={category.id}
                      onMouseLeave={closeNavbarLc}
                      onMouseEnter={() => openNavbarLc(category.d1_Id)}>
                      <Link to={`/productsearch?productId=${category.d2_Id}`} className="Header-Category-Color">
                        {category.menuName}
                      </Link>
                    </div>
                  ))}

              </div>
              <div className="navbar_Bottom_right_theme_3">
                <img src="" alt="" />
                <img src="" alt="" />
                <img src="" alt="" />
              </div>
            </div>

            {LcNavbar && (
              <Theme3NavbarOnhover
                CategoryData={CategoryData}
                openNavbarLc={openNavbarLc}
                closeNavbarLc={closeNavbarLc}
                parentId={parentId}
              />
            )}
          </div>
          {Loading && <Loader1 />}
          {/* <NavBarMobile1 /> */}
          <NavBarMobile_Lc wishList={wishList} />
        </div>
      );
    }
  };

  return (
    <div>
      {showButton()}
    </div>

  );
};

export default Navbar_lc;
