import React from "react";
import "../Css/Footer.scss"
import "../Css/mobilecss.scss"
import Footer1 from "./Footer/Footer1";
import Footertheme3 from "./Footer/Footertheme3";
// import Footer2 from "./Footer/Footer2";
// import Footer3 from "./Footer/Footer3";
// import SocialMediaLink3 from "./SocialMediaBox/SocialMediaLink3";

const Footer = (props) => {
  return (
    <div>
      <Footer1 currentUser={props.currentUser} />
      {/* <Footertheme3 /> */}
      {/* <Footer2 /> */}
      {/* <Footer3 /> */}

    </div>
  );
};

export default Footer;
